import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramState } from './program.state';

export const programFeatureKey = 'program';
export const selectProgramFeature = createFeatureSelector<ProgramState>(programFeatureKey);
export const selectAllPrograms = createSelector(
  selectProgramFeature,
  (programState: ProgramState) => programState.programs
);
export const selectLoadingPrograms = createSelector(
  selectProgramFeature,
  (programState: ProgramState) => programState.loadingPrograms
);
export const selectProgram = createSelector(
  selectProgramFeature,
  (programState: ProgramState) => programState.program
);
export const selectLoadingProgram = createSelector(
  selectProgramFeature,
  (programState: ProgramState) => programState.loadingProgram
);
export const selectProgramUpdated = createSelector(
  selectProgramFeature,
  (programState: ProgramState) => programState.programUpdated
);
export const selectProgramDeleted = createSelector(
  selectProgramFeature,
  (programState: ProgramState) => programState.programDeleted
);
