import { createAction, props } from '@ngrx/store';
import { Program } from './program.model';

export enum ProgramActionType {
  GET_ALL_PROGRAMS = '[Program] Get All Programs',
  GET_ALL_PROGRAMS_SUCCESS = '[Program] Get All Programs :: Success',
  GET_PROGRAM = '[Program] Get Program',
  GET_PROGRAM_SUCCESS = '[Program] Get Program :: Success',
  CREATE_PROGRAM = '[Program] Create Program',
  CREATE_PROGRAM_SUCCESS = '[Program] Create Program :: Success',
  UPDATE_PROGRAM = '[Program] Update Program',
  UPDATE_PROGRAM_SUCCESS = '[Program] Update Program :: Success',
  RESET_PROGRAM_UPDATED = '[Program] Reset Program Updated',
  DELETE_PROGRAM = '[Program] Delete Program',
  DELETE_PROGRAM_SUCCESS = '[Program] Delete Program :: Success',
  RESET_PROGRAM_DELETED = '[Program] Reset Program Deleted',
}

export const getAllPrograms = createAction(ProgramActionType.GET_ALL_PROGRAMS);
export const getAllProgramsSuccess = createAction(
  ProgramActionType.GET_ALL_PROGRAMS_SUCCESS,
  props<{ programs: Program[] }>()
);
export const getProgram = createAction(ProgramActionType.GET_PROGRAM, props<{ id: string }>());
export const getProgramSuccess = createAction(
  ProgramActionType.GET_PROGRAM_SUCCESS,
  props<{ program: Program }>()
);
export const createProgram = createAction(
  ProgramActionType.CREATE_PROGRAM,
  props<{ program: Program }>()
);
export const createProgramSuccess = createAction(
  ProgramActionType.CREATE_PROGRAM_SUCCESS,
  props<{ program: Program }>()
);
export const updateProgram = createAction(
  ProgramActionType.UPDATE_PROGRAM,
  props<{ program: Program }>()
);
export const updateProgramSuccess = createAction(
  ProgramActionType.UPDATE_PROGRAM_SUCCESS,
  props<{ program: Program }>()
);
export const resetProgramUpdated = createAction(ProgramActionType.RESET_PROGRAM_UPDATED);
export const deleteProgram = createAction(
  ProgramActionType.DELETE_PROGRAM,
  props<{ id: number }>()
);
export const deleteProgramSuccess = createAction(ProgramActionType.DELETE_PROGRAM_SUCCESS);
export const resetProgramDeleted = createAction(ProgramActionType.RESET_PROGRAM_DELETED);
